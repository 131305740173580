import React from "react";
import { Card, Col, Container, Row } from "reactstrap";
import TechSlider from "./techSlider";

function Stack(props) {
  return (
    <>
      <div className="cd-section" id="stack">
        <Container>
          <Row className="text-center">
            <Col>
              <h2 className="title mb-1">Технологии которые применяю</h2>
            </Col>
          </Row>
          <Row className=" justify-content-center">
            <Col className="" lg="3">
              <Card className="card-plain w-auto ">
                <p>FrontEnd:</p>
                <ul>
                  <li className="description">JS(ES6+)</li>
                  <li className="description">TypeScript</li>
                  <li className="description">React</li>
                  <li className="description">
                    Redux, Redux Thunk, Redux Toolkit
                  </li>
                  <li className="description">CSS / SCSS / PostCSS / Ant-D</li>
                  <li className="description">React Testing Library & Jest</li>
                </ul>
              </Card>
            </Col>
            <Col className="" lg="3">
              <Card className="card-plain w-auto">
                <p>BackEnd:</p>
                <ul>
                  <li className="description">Nest.js, Express</li>
                  <li className="description">PostgreSQL</li>
                  <li className="description">ORM: Sequelize, Prisma</li>
                  <li className="description">JWT</li>
                </ul>
              </Card>
            </Col>
            <Col className="" lg="3">
              <Card className="card-plain w-auto">
                <p>Остальное:</p>
                <ul>
                  <li className="description">Git / Git flow / npm / yarn</li>
                  <li className="description">
                    CI-CD (Linux, Docker, Nginx, github actions)
                  </li>
                  <li className="description">ESLint, Prettier</li>
                </ul>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <TechSlider />
    </>
  );
}

export default Stack;

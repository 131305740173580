/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Container,
  Row,
  Col,
} from "reactstrap";

export default function Accordion() {
  const [collapse, setCollapse] = React.useState(1);
  const openCollapse = (collapse) => {
    setCollapse(collapse);
  };

  return (
    <>
      <div className="cd-section " id="faq">
        {/* ********* Accordion 1 ********* */}
        <div className="accordion-1">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title mb-1 mt-5">Подробнее обо мне</h2>
                <div className="section-space" />
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto" md="12">
                <div className="accordion">
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Button
                          className="w-100 text-left "
                          color="link"
                          aria-expanded={collapse === 1}
                          onClick={() => openCollapse(1)}
                        >
                          Где обучался
                          <i className="tim-icons icon-minimal-down float-right" />
                        </Button>
                      </h5>
                    </CardHeader>
                    <Collapse isOpen={collapse === 1}>
                      <CardBody>
                        <ol>
                          <li className="description">
                            Высшее экономическое образование. Далее занялся
                            самообразованием в областях вебмастеринга и seo.
                          </li>
                          <li className="description">
                            В 2021 окончил курс профессиональная верстка сайтов
                            от HTML Academy.
                          </li>
                          <li className="description">
                            В 2022 окончил обучение в Result.school (Владилен
                            Минин) по программе frontend (10 месяцев). Подробнее
                            о курсе{" "}
                            <a
                              href={
                                "https://vladilen.notion.site/Junior-Frontend-Developer-8091f80f5cf8491997e540e227ebf8d7"
                              }
                              target={"_blank"}
                              rel={"noreferrer nofollow"}
                            >
                              можно почитать тут.
                            </a>
                          </li>
                          <li className="description">
                            В 2023 окончил курс middle frontend разработчик от
                            Яндекса (6 мес). Получен диплом о профессиональной
                            переподготовке. Подробнее про программу курса{" "}
                            <a
                              href={
                                "https://cat.2035.university/rall/course/17139/"
                              }
                              target={"_blank"}
                              rel={"noreferrer nofollow"}
                            >
                              можно почитать тут.
                            </a>{" "}
                          </li>
                        </ol>
                        <p>
                          Непрерывно обучаюсь новому, читаю книги, документацию
                          и смотрю англоязычные видео по интересующим меня
                          технологиям. Конспекты веду в obsidian.
                        </p>
                      </CardBody>
                    </Collapse>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Button
                          className="w-100 text-left"
                          color="link"
                          aria-expanded={collapse === 2}
                          onClick={() => openCollapse(2)}
                        >
                          Про бекграунд{" "}
                          <i className="tim-icons icon-minimal-down float-right" />
                        </Button>
                      </h5>
                    </CardHeader>
                    <Collapse isOpen={collapse === 2}>
                      <CardBody>
                        <p className={"description text-justify"}>
                          {" "}
                          С 2011 увлёкся web технологиями и начал изучать html,
                          css, seo. С 2013 начал делать сайты как для себя, так
                          и под заказ на различных CMS (список проектов и ссылки
                          доступны во вкладке Вебмастер). В силу работы с CMS
                          изучил основы php для написания простых хаков и
                          плагинов. В 2020 освоил C# по книге Шилдта для
                          базового понимания языка и автоматизации рутинных
                          операций в seo. Втянулся в кодинг и начал путь в
                          Fullstack на JS/TS.{" "}
                        </p>
                        <p>
                          Вебмастерский бекграунд наделил меня некоторыми
                          навыками, которые могут пригодиться в коммерческой
                          разработке:
                        </p>
                        <ul>
                          <li className="description">
                            Быстрое создание сайтов под любой вид бизнеса на
                            различных CMS. Понимание требований, болей, UX.
                          </li>
                          <li className="description">
                            Администрирование Linux серверов, работа с
                            nginx/apache;
                          </li>
                          <li className="description">SEO экспертиза;</li>
                          <li className="description">
                            Автоматизация с ZennoPoster и C#;
                          </li>
                          <li className="description">Базовое навыки SQL;</li>
                          <li className="description">
                            Декомпозиция задач и планирование проектов;
                          </li>
                        </ul>
                        <p>
                          Наиболее запомнившиеся мне достижения за практику
                          вебмастером:
                        </p>
                        <ul>
                          <li className="description">
                            Создал под ключ крупный интернет-магазин
                            канцтоваров;
                          </li>
                          <li className="description">
                            В кратчайшие сроки вывел в топ Яндекса 190
                            конкурентных запросов без покупки ссылок;
                          </li>
                          <li className="description">
                            За месяц с нуля сделал функциональную мобильную CRM
                            систему из предоставленного excel файла с помощью
                            Glide;
                          </li>
                          <li className="description">
                            За 2 недели сделал переезд сайта с самописной CMS на
                            современный ecommerce движок prestashop с миграцией
                            базы данных.
                          </li>
                        </ul>
                      </CardBody>
                    </Collapse>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Button
                          className="w-100 text-left "
                          color="link"
                          aria-expanded={collapse === 3}
                          onClick={() => openCollapse(3)}
                        >
                          Про SEO навыки
                          <i className="tim-icons icon-minimal-down float-right" />
                        </Button>
                      </h5>
                    </CardHeader>
                    <Collapse isOpen={collapse === 3}>
                      <CardBody>
                        <p className={"description text-justify"}>
                          Экспертиза в продвижении сайтов в топ по ключевым
                          запросам в поисковых системах Яндекс и Google на
                          уровне middle.{" "}
                        </p>
                        <ul>
                          <li className="description">
                            Опыт работы SEO-специалистом с{" "}
                            <a
                              href="https://searchengines.guru/ru/users/1036305"
                              target="_blank"
                              rel={"noreferrer nofollow"}
                            >
                              2013 года
                            </a>
                            ;
                          </li>
                          <li className="description">
                            Опыт сбора и кластеризации крупных семантических
                            ядер и работы в высококонкурентных тематиках;
                          </li>
                          <li className="description">
                            Высокий уровень знания инструментов: Keys.so, Excel,
                            KeyCollector, Penguin, Яндекс Вебмастер, Яндекс
                            Метрика, Google Analytics, Search Console;
                          </li>
                          <li className="description">
                            Высокий уровень владения ZennoPoster - от написания
                            парсеров и рутинных автоматизаций, до разработки
                            сложных ботов на C#.
                          </li>
                        </ul>
                      </CardBody>
                    </Collapse>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Button
                          className="w-100 text-left "
                          color="link"
                          aria-expanded={collapse === 4}
                          onClick={() => openCollapse(4)}
                        >
                          Про английский{" "}
                          <i className="tim-icons icon-minimal-down float-right" />
                        </Button>
                      </h5>
                    </CardHeader>
                    <Collapse isOpen={collapse === 4}>
                      <CardBody>
                        <p className={"description text-justify"}>
                          Уровень B2{" "}
                          <a
                            href={"https://bit.ly/3NroleW"}
                            target={"_blank"}
                            rel={"noreferrer nofollow"}
                          >
                            {" "}
                            по этому тесту
                          </a>{" "}
                          (50 минут).
                          <br />
                          Понимаю речь, гуглю на английском, читаю документацию
                          (не к адронному коллайдеру). Словарный запас ~ 5000
                          слов. Письма писать и общаться по чатам - без проблем.
                          С грамматикой и говорением есть трудности в силу малой
                          практики, но в данный момент работаю над этим.
                        </p>
                      </CardBody>
                    </Collapse>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h5 className="mb-0">
                        <Button
                          className="w-100 text-left "
                          color="link"
                          aria-expanded={collapse === 5}
                          onClick={() => openCollapse(5)}
                        >
                          Про софт скилы{" "}
                          <i className="tim-icons icon-minimal-down float-right" />
                        </Button>
                      </h5>
                    </CardHeader>
                    <Collapse isOpen={collapse === 5}>
                      <CardBody>
                        <p className={"description text-justify"}>
                          Самостоятелен, организован, ответственно подхожу к
                          выполнению поставленных задач. Мотивирован на
                          профессиональное развитие и выполнение задач различной
                          сложности. Веду проекты через канбан и использую
                          фоновый тайм-трекинг для контроля продуктивности. Не
                          имею вредных привычек. Для эффективной удаленной
                          работы организована рабочая станция со всем
                          необходимым оборудованием.{" "}
                        </p>
                        <p className={"description text-justify"}>
                          В период активного создания сайтов{" "}
                          <a
                            href="https://files.aleksandrl.ru/sites/portfolio/img/advego.png"
                            target={"_blank"}
                            rel="nofollow noopener noreferrer"
                          >
                            {" "}
                            много взаимодействовал
                          </a>{" "}
                          с различными специалистами в смежных областях.
                        </p>
                        <p className={"description text-justify"}>
                          Во время интенсивного обучения фронтенду принимал
                          участие во всех командных активностях и хакатонах. В
                          командном проекте принимал активное участие в
                          разработке, проводил демо проекта и выступал на
                          защите.
                        </p>
                      </CardBody>
                    </Collapse>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END Accordion 1 ********* */}
      </div>
    </>
  );
}

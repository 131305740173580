/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

export default function FooterComponent() {
  return (
    <>
      <div className="contactus-3" id={"contacts"} style={{ paddingTop: 15 }}>
        <Container>
          <Row>
            <Col className="text-center" md="12">
              <h2>Контакты</h2>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row className="infos align-items-center justify-content-center">
            <Col lg="2">
              <div className="info info-hover">
                <div className="icon icon-primary">
                  <img
                    alt="..."
                    className="bg-blob"
                    src={require("assets/img/feature-blob/primary.png").default}
                  />
                  <i className="tim-icons icon-square-pin" />
                </div>
                <h4 className="info-title">Санкт-Петербург</h4>
              </div>
            </Col>
            <Col lg="2">
              <div className="info info-hover">
                <div className="icon icon-info">
                  <img
                    alt="..."
                    className="bg-blob"
                    src={require("assets/img/feature-blob/info.png").default}
                  />
                  <i className="tim-icons icon-email-85" />
                </div>
                <h4 className="info-title">
                  <a
                    href={"mailto:hello@aleksandrl.ru"}
                    style={{ color: "#fff", textDecoration: "underline" }}
                    target={"_blank"}
                    rel={"noreferrer nofollow"}
                  >
                    {" "}
                    hello@aleksandrl.ru
                  </a>
                </h4>
              </div>
            </Col>
            <Col lg="2">
              <div className="info info-hover">
                <div className="icon icon-warning">
                  <img
                    alt="..."
                    className="bg-blob"
                    src={require("assets/img/feature-blob/warning.png").default}
                  />
                  <i className="fab fa-telegram" />
                </div>
                <h4 className="info-title">
                  {" "}
                  <a
                    href="https://bit.ly/3tNCLhX"
                    style={{ color: "#fff", textDecoration: "underline" }}
                    target={"_blank"}
                    rel={"noreferrer nofollow"}
                  >
                    GitHub
                  </a>
                  ,{" "}
                  <a
                    href="https://bit.ly/3JPKb9P"
                    style={{ color: "#fff", textDecoration: "underline" }}
                    target={"_blank"}
                    rel={"noreferrer nofollow"}
                  >
                    LinkedIn
                  </a>
                  ,{" "}
                  <a
                    href="#"
                    style={{ color: "#fff", textDecoration: "underline" }}
                    target={"_blank"}
                    rel={"noreferrer nofollow"}
                  >
                    TG (пишите на HH)
                  </a>
                </h4>
              </div>
            </Col>
            <Col lg="2" style={{ border: "none" }}>
              <div className="info info-hover">
                <div className="icon icon-success">
                  <img
                    alt="..."
                    className="bg-blob"
                    src={require("assets/img/feature-blob/success.png").default}
                  />
                  <i className="tim-icons icon-single-02" />
                </div>
                <h4 className="info-title">
                  <a
                    href={"#"}
                    target={"_blank"}
                    rel={"noreferrer nofollow"}
                    style={{ color: "#fff", textDecoration: "underline" }}
                  >
                    Резюме в pdf
                  </a>
                </h4>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

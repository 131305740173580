/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

export default function MainTitle() {
  return (
    <>
      <div className="section section-basic-components" id={"about"}>
        <div className="squares square1" style={{ opacity: 0.1 }} />
        <div className="squares square2" style={{ opacity: 0.1 }} />
        <div className="squares square3" style={{ opacity: 0.1 }} />
        <div
          className="squares square5"
          style={{ top: "70%", height: 80, width: 80, opacity: 0.1 }}
        />
        <div className="squares square6" style={{ opacity: 0.1 }} />
        <div className="squares square7" style={{ opacity: 0.1 }} />
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="5" md="7">
              <h1 className="title">Александр Лоскутов</h1>
              <h5 className="category text-primary">
                <strong>JavaScript разработчик</strong>
              </h5>
              <div className="description mt-5 text-justify">
                <p>Коротко обо мне:</p>
                <ul>
                  <li className="description">Делаю сайты с 2013 года;</li>
                  <li className="description">
                    Сделал более 10 проектов для бизнеса;
                  </li>
                  <li className="description">SEO специалист middle уровня;</li>
                  <li className="description">Английский B2;</li>
                </ul>
                <p>Стек технологий:</p>
                <ul>
                  <li className="description">JS (ES6+);</li>
                  <li className="description">TypeScript;</li>
                  <li className="description">React;</li>
                  <li className="description">Redux;</li>
                  <li className="description">NestJS</li>
                  <li className="description">и др. подробнее ниже;</li>
                </ul>
              </div>
            </Col>
            <Col lg="6" md="12">
              <div className="image-container">
                <img
                  alt="..."
                  className="table-img"
                  style={{ height: 110, top: "43%", left: "75%" }}
                  src={require("assets/img/tech/title/react.png").default}
                />
                <img
                  alt="..."
                  className="share-btn-img"
                  style={{ height: 110, top: "75%", left: "70%" }}
                  src={require("assets/img/tech/title/redux.png").default}
                />
                <img
                  alt="..."
                  className="coloured-card-btn-img"
                  style={{ height: 110, top: "73%", left: "5%" }}
                  src={require("assets/img/tech/title/ts.png").default}
                />
                <img
                  alt="..."
                  className="coloured-card-img"
                  style={{ height: 180, top: "1%" }}
                  src={require("assets/img/tech/title/node.png").default}
                />
                <img
                  alt="..."
                  className="linkedin-btn-img"
                  style={{ height: 110, top: "10%" }}
                  src={require("assets/img/tech/title/js.png").default}
                />
                <img
                  alt="..."
                  className="path path3"
                  src={require("assets/img/path3.png").default}
                />
                {/* SVG Illustration */}
                <figure className="ie-non-standard-hero-shape">
                  <svg
                    x="0px"
                    y="0px"
                    viewBox="10 12 878.9 907"
                    style={{ enableBackground: "new 10 12 878.9 907" }}
                    xmlSpace="preserve"
                    className="injected-svg js-svg-injector"
                  >
                    <g>
                      <defs>
                        <path
                          id="firstShape"
                          d="M329.15,403.44c30.22-62,26.51-223.94,94.06-268.46C479,98.23,560.16,257,700.68,151.61c71.25-53.44,85.54,81,160.38,172.6C1008.5,504.74,881.5,639.14,825.35,686.6c-62.54,52.85-246.14,24.42-386.7,79.28S214.07,834,202.07,702C190.39,573.57,288.69,486.43,329.15,403.44Z"
                        />
                      </defs>
                      <clipPath id="secondShape">
                        <use
                          xlinkHref="#firstShape"
                          style={{ overflow: "visible" }}
                        />
                      </clipPath>
                      <g clipPath="url(#secondShape)">
                        <image
                          id="imageShape1"
                          style={{ overflow: "visible", opacity: 0.8 }}
                          width="900"
                          height="900"
                          xlinkHref={
                            require("assets/img/aleksandr1.png").default
                          }
                          transform="matrix(0.9488 0 0 0.9488 25 53.1187)"
                        />
                      </g>
                    </g>
                  </svg>
                </figure>
                {/* End SVG Illustration */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>{" "}
    </>
  );
}

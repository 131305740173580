/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  UncontrolledCollapse,
  Row,
  Col,
  NavItem,
  NavLink,
} from "reactstrap";
import Headroom from "headroom.js";

export default function NavbarComponent() {
  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-scroll"));
    // initialise
    headroom.init();
  }, []);
  const scrollPageAndCloseMenu = (e, id) => {
    e.preventDefault();
    if (document.getElementById(id) !== null) {
      document.getElementById(id).scrollIntoView();
    }
    const close = document.getElementById("example-navbar-info");
    if (close !== null) {
      close.click();
    }
  };
  const handleOnClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <Navbar
      className="bg-info"
      expand="lg"
      id={"navbar-scroll"}
      style={{ opacity: 0.9 }}
    >
      <Container>
        <div className="navbar-translate">
          <NavbarBrand tag={Link} to={handleOnClick}>
            AleksandrL.ru
          </NavbarBrand>
          <button className="navbar-toggler" id="example-navbar-info">
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <UncontrolledCollapse navbar toggler="#example-navbar-info">
          <div className="navbar-collapse-header">
            <Row>
              <Col className="collapse-brand" xs="6">
                <Link to={"/"}>AleksanrL• Ru</Link>
              </Col>
              <Col className="collapse-close text-right" xs="6">
                <button className="navbar-toggler" id="example-navbar-info">
                  <i className="tim-icons icon-simple-remove" />
                </button>
              </Col>
            </Row>
          </div>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink
                href="#projects"
                onClick={(e) => scrollPageAndCloseMenu(e, "projects")}
              >
                Проекты
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#stack"
                onClick={(e) => scrollPageAndCloseMenu(e, "stack")}
              >
                Стек
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#faq"
                onClick={(e) => scrollPageAndCloseMenu(e, "faq")}
              >
                Обо мне
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#contacts"
                onClick={(e) => scrollPageAndCloseMenu(e, "contacts")}
              >
                Резюме
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#contacts"
                onClick={(e) => scrollPageAndCloseMenu(e, "contacts")}
              >
                Контакты
              </NavLink>
            </NavItem>
          </Nav>
        </UncontrolledCollapse>
      </Container>
    </Navbar>
  );
}

import React from "react";
import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";
import { Col, Container, Row } from "reactstrap";

// custom previous button for the slick component
// custom next button for the slick component
const settings = {
  container: "#tiny-slider",
  items: 4,
  centerMode: true,
  autoplay: true,
  autoplayTimeout: 1000,
  freezable: false,
  mode: "carousel",
  controls: false,
  nav: false,
  mouseDrag: true,
  gutter: 10,
  edgePadding: 10,
  autoWidth: true,
  infiniteLoop: true,
};
const imgs = [
  "img/csharp.png",
  "img/docker.png",
  "img/git.png",
  "img/html5.png",
  "img/js.png",
  "img/mongodb.png",
  "img/mysql.png",
  "img/nix.png",
  "img/nodejs.png",
  "img/react.png",
  "img/redux.png",
  "img/typescript.png",
  "img/zennoposter.png",
];
function TechSlider(props) {
  return (
    <div className="section pt-0 pb-0 mt-1 mb-1">
      <Container id={"tiny-slider"}>
        <Row>
          <Col>
            <TinySlider settings={settings}>
              {imgs.map((el, index) => (
                <div key={index} style={{ position: "relative" }}>
                  <img src={el} data-src={el} alt="" />
                </div>
              ))}
            </TinySlider>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default TechSlider;

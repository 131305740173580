import React from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

function Projects() {
  const [activeTab, setActiveTab] = React.useState("0");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div className="section pt-0 pb-0 mt-1 mb-1 cd-section " id="projects">
      <div
        className="projects-2 project-raised"
        style={{ paddingBottom: 0, paddingTop: 0 }}
      >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center mb-5" lg="8">
              <h2 className="title">Мои проекты</h2>
              <div className="section-space" />
            </Col>
          </Row>
          <Nav
            className="nav-pills-info nav-pills-icons nav-pills justify-content-center"
            pills
            role="tablist"
          >
            <NavItem
              style={{
                cursor: activeTab === "0" ? "auto" : "pointer",
              }}
            >
              <NavLink
                className={activeTab === "0" ? "active" : ""}
                onClick={() => {
                  toggle("0");
                }}
              >
                <i className="tim-icons icon-book-bookmark" />
                PlayWord
              </NavLink>
            </NavItem>
            <NavItem
              style={{
                cursor: activeTab === "1" ? "auto" : "pointer",
              }}
            >
              <NavLink
                className={activeTab === "1" ? "active" : ""}
                onClick={() => {
                  toggle("1");
                }}
              >
                <i className="tim-icons icon-chat-33" />
                Мессенджер
              </NavLink>
            </NavItem>
            <NavItem
              style={{
                cursor: activeTab === "2" ? "auto" : "pointer",
              }}
            >
              <NavLink
                className={activeTab === "2" ? "active" : ""}
                onClick={() => {
                  toggle("2");
                }}
              >
                <i className="tim-icons icon-controller" />
                Игра
              </NavLink>
            </NavItem>
            <NavItem
              style={{
                cursor: activeTab === "3" ? "auto" : "pointer",
              }}
            >
              <NavLink
                className={activeTab === "3" ? "active" : ""}
                onClick={() => {
                  toggle("3");
                }}
              >
                <i className="tim-icons icon-bag-16" />
                Магазин
              </NavLink>
            </NavItem>
            <NavItem
              style={{
                cursor: activeTab === "4" ? "auto" : "pointer",
              }}
            >
              <NavLink
                className={activeTab === "4" ? "active" : ""}
                onClick={() => {
                  toggle("4");
                }}
              >
                <i className="tim-icons icon-laptop" />
                Вебмастер
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent className="tab-space" activeTab={"project" + activeTab}>
            <TabPane tabId="project0">
              <Row>
                <Col>
                  <p className={"description text-justify"}>
                    Приложение-тренажер для изучения английских слов по технике
                    интервального повторения. Fullstack пет-проект с нуля от
                    идеи до деплоя в прод (playword.ru). Делалось для себя,
                    друзей и знакомых кто изучает язык. Устанавливается на
                    телефон и PC в 1 клик как PWA.
                  </p>
                  <p>
                    Проект доступен по ссылке:{" "}
                    <a
                      href="https://playword.ru/"
                      target={"_blank"}
                      rel={"noreferrer nofollow"}
                    >
                      playword.ru
                    </a>
                    . Короткое видео демонстрации функционала можно{" "}
                    <a
                      href="https://files.aleksandrl.ru/sites/playword/demo-video.webm"
                      target={"_blank"}
                      rel={"nofollow noreferrer"}
                    >
                      посмотреть по ссылке.
                    </a>
                    . Ссылка на{" "}
                    <a
                      href={"https://github.com/aleksandr-loskutov/play-word"}
                      target={"_blank"}
                      rel={"noreferrer nofollow"}
                    >
                      репозиторий проекта.
                    </a>
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-between mt-2">
                <Col md="4">
                  Стек технологий:
                  <ul>
                    <li className="description">TypeScript;</li>
                    <li className="description">React;</li>
                    <li className="description">
                      Redux Toolkit - библиотека управления состоянием.
                    </li>
                    <li className="description">
                      Antd — библиотека компонент;
                    </li>
                    <li className="description">PostCSS — препроцессор;</li>
                    <li className="description">Vite — сборщик;</li>
                    <li className="description">Lerna — монорепозиторий;</li>
                    <li className="description">NestJS — бекенд;</li>
                    <li className="description">PostgreSQL — БД;</li>
                    <li className="description">Prisma — ORM;</li>
                    <li className="description">EsLint, Prettier.</li>
                    <li className="description">
                      Docker, nginx, и github actions для автодеплоя;
                    </li>
                  </ul>
                </Col>
                <Col md="7">
                  <Card className="mt-0">
                    <div className="card-image h-100">
                      <img
                        alt="..."
                        className="img rounded"
                        style={{ opacity: 0.8 }}
                        src={
                          "https://files.aleksandrl.ru/sites/playword/demo-video-poster.png"
                        }
                      />
                    </div>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="project1">
              <Row>
                <Col>
                  <p className={"description text-justify"}>
                    Приложение веб-чат с возможностью регистрации и переписки.
                    Без использования фреймворков. Разработано на шаблонизаторе,
                    ооп и сокетах. Имеет всего 3 зависимости: express для
                    раздачи статики, handlebars для шаблонизации и nanoid для
                    генерации id. Вёрстка с нуля по макету.
                  </p>
                  <p>
                    Проект доступен по ссылке:{" "}
                    <a
                      href="https://messenger.aleksandrl.ru/"
                      target={"_blank"}
                      rel={"noreferrer nofollow"}
                    >
                      messenger.aleksandrl.ru.
                    </a>
                    . Короткое видео демонстрации функционала можно{" "}
                    <a
                      href="https://www.berrycast.com/conversations/f93c3464-fca4-5ac6-b8e4-40fec9627002"
                      target={"_blank"}
                      rel={"nofollow noreferrer"}
                    >
                      посмотреть по ссылке.
                    </a>
                    . Ссылка на{" "}
                    <a
                      href={
                        "https://github.com/aleksandr-loskutov/middle.messenger.praktikum.yandex"
                      }
                      target={"_blank"}
                      rel={"noreferrer nofollow"}
                    >
                      репозиторий проекта.
                    </a>
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-between mt-2">
                <Col md="4">
                  Стек технологий:
                  <ul>
                    <li className="description">TypeScript</li>
                    <li className="description">Express — веб-сервер;</li>
                    <li className="description">Handlebars — шаблонизатор;</li>
                    <li className="description">SASS — препроцессор;</li>
                    <li className="description">Webpack — сборщик;</li>
                    <li className="description">
                      Websockets — обмен сообщениями;
                    </li>
                    <li className="description">Mocha & Chai для тестов;</li>
                    <li className="description">
                      EsLint, Stylelint, Prettier.
                    </li>
                    <li className="description">
                      Docker с github actions для автодеплоя;
                    </li>
                  </ul>
                </Col>
                <Col md="7">
                  <Card className="mt-0">
                    <div className="card-image h-100">
                      <img
                        alt="..."
                        className="img rounded"
                        style={{ opacity: 0.8 }}
                        src={
                          "https://files.aleksandrl.ru/sites/portfolio/img/messenger.png"
                        }
                      />
                    </div>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="project2">
              <Row>
                <Col>
                  <p className={"description text-justify"}>
                    Командная разработка игры на canvas. Сама игра разработана
                    на чистом js, все остальные разделы сайта сделаны на React.
                    Разработка велась по методологии scrum и git flow с крос
                    ревью. Реализована сама игра, регистрация, авторизация,
                    oAuth, профиль, лидерборд. форум. Конкретно я отвечал за
                    таски: страница регистрации, API авторизации и регистрации,
                    HOC & hook для авторизации, Redux Thunk, oAuth авторизация,
                    тестирование, PostgreSQL, весь бекенд форума, модели
                    sequelize, проксирование запросов, связку фронта с бекендом,
                    настройку сервера и автодеплоймент на домен. Проект доступен
                    по адресу:{" "}
                    <a
                      href="https://spacerunner.ru/sign-up"
                      target={"_blank"}
                      rel={"noreferrer nofollow"}
                    >
                      spacerunner.ru
                    </a>
                    . Короткое видео демонстрации функционала можно{" "}
                    <a
                      href="https://www.berrycast.com/conversations/8b50b284-959c-5c16-b106-b28f441c848f"
                      target={"_blank"}
                      rel={"nofollow noreferrer"}
                    >
                      посмотреть тут
                    </a>
                    . Ссылка на{" "}
                    <a
                      href={"https://github.com/Stack-overflow-runners/game"}
                      target={"_blank"}
                      rel={"noreferrer nofollow"}
                    >
                      репозиторий проекта.
                    </a>
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-between mt-2">
                <Col md="4">
                  Стек технологий:
                  <ul>
                    <li className="description">TypeScript;</li>
                    <li className="description">React;</li>
                    <li className="description">Redux (RTK);</li>
                    <li className="description">Canvas API;</li>
                    <li className="description">PostCSS & Ant Design;</li>
                    <li className="description">
                      React Testing Library & Jest;
                    </li>
                    <li className="description">Vite;</li>
                    <li className="description">PostgreSQL & sequelize</li>
                    <li className="description">Express;</li>
                    <li className="description">EsLint, Prettier.</li>
                    <li className="description">
                      Docker с github actions для автодеплоя;
                    </li>
                  </ul>
                </Col>
                <Col md="7">
                  <Card className="mt-0">
                    <div className="card-image h-100">
                      <img
                        alt="..."
                        className="img rounded"
                        style={{ opacity: 0.8 }}
                        src={
                          "https://files.aleksandrl.ru/sites/portfolio/img/spacerunner-gameplay4.png"
                        }
                      />
                    </div>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="project3">
              <Row>
                <Col>
                  <p className={"description text-justify"}>
                    Магазин на MERN стеке. Реализован весь основной функционал
                    присущий ecommerce: регистрация, авторизация, каталог,
                    карточка товара, корзина, оформление заказа, админка,
                    добавление и удаление товаров, статусы заказов, личный
                    кабинет покупателя. Backend и REST API для магазина делался
                    с нуля. Для фронта использовал библиотеку компонент. Это мой
                    первый проект на React по сути, поэтому без TS.{" "}
                  </p>
                  <p>
                    Проект доступен по адресу:{" "}
                    <a
                      href="https://mern.aleksandrl.ru/"
                      target={"_blank"}
                      rel={"noreferrer nofollow"}
                    >
                      mern.aleksandrl.ru.
                    </a>
                    <br />
                    Короткое видео демонстрации функционала можно{" "}
                    <a
                      href="https://www.berrycast.com/conversations/64feda24-801c-57af-b38a-62dab5beecc2"
                      target={"_blank"}
                      rel={"nofollow noreferrer"}
                    >
                      посмотреть тут.
                      <br />
                    </a>
                    Ссылка на{" "}
                    <a
                      href={"https://github.com/aleksandr-loskutov/mern-shop"}
                      target={"_blank"}
                      rel={"noreferrer nofollow"}
                    >
                      репозиторий проекта.
                    </a>
                  </p>
                </Col>
                <Col md="4" className="">
                  Стек технологий:
                  <ul>
                    <li className="description">JS (ES6+);</li>
                    <li className="description">React;</li>
                    <li className="description">Redux (RTK);</li>
                    <li className="description">Bootstrap 4 UI kit;</li>
                    <li className="description">Express;</li>
                    <li className="description">MongoDB & mongoose;</li>
                    <li className="description">Gulp;</li>
                    <li className="description">Docker;</li>
                  </ul>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md="4">
                  <Card>
                    <div className="card-image h-100">
                      <img
                        alt="..."
                        className="img rounded"
                        style={{ opacity: 0.8 }}
                        src={require("../assets/img/projects/lk.jpg").default}
                      />
                    </div>
                    <CardBody className="text-left">
                      <CardFooter className="mt-0">
                        <div className="author">
                          <img
                            alt="..."
                            className="avatar img-raised bg-transparent"
                            src={require("assets/img/tech/react.png").default}
                          />
                          <span className="ml-1">
                            Полноценный личный кабинет
                          </span>
                        </div>
                      </CardFooter>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card>
                    <div className="card-image h-100">
                      <img
                        alt="..."
                        className="img rounded"
                        style={{ opacity: 0.8 }}
                        src={
                          require("../assets/img/projects/admin.jpg").default
                        }
                      />
                    </div>
                    <CardBody className="text-left">
                      <CardFooter className="mt-0">
                        <div className="author">
                          <img
                            alt="..."
                            className="avatar img-raised bg-transparent"
                            src={require("assets/img/tech/react.png").default}
                          />
                          <span className="ml-1">Полноценная админка</span>
                        </div>
                      </CardFooter>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card>
                    <div className="card-image h-100">
                      <img
                        alt="..."
                        className="img rounded"
                        style={{ opacity: 0.8 }}
                        src={
                          require("../assets/img/projects/construct.jpg")
                            .default
                        }
                      />
                    </div>
                    <CardBody className="text-left">
                      <CardFooter className="mt-0">
                        <div className="author">
                          <img
                            alt="..."
                            className="avatar img-raised bg-transparent"
                            src={require("assets/img/tech/react.png").default}
                          />
                          <span className="ml-1">
                            Конструктор параметров у товара
                          </span>
                        </div>
                      </CardFooter>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="project4">
              <Row>
                <Col>
                  <h3 className="mb-1">Фриланс проекты</h3>
                  <p className="description">
                    Многие проекты из списка ниже были сделаны на стеке LAMP
                    (Linux, Apache, MySQL, PHP) под заказ на различных CMS.
                  </p>
                  <p className="description"> Список проектов (с 2013 г.):</p>
                  <ul>
                    <li className="description">
                      <span className="text-success">
                        konsilyeri (wordpress)
                      </span>{" "}
                      - сайт помощи призывникам и его продвижение. Был сделан
                      под продажу и успешно{" "}
                      <a
                        href={"https://bit.ly/3wH6L0G"}
                        rel={"nofollow noreferrer"}
                        target={"_blank"}
                      >
                        продан на аукционе
                      </a>
                      ;
                    </li>
                    <li className="description">
                      <span className="text-success">
                        odecobra (prestashop)
                      </span>{" "}
                      - информационный сайт-магазин по эмулятору привода cobra
                      для PS3;
                    </li>
                    <li className="description">
                      <span className="text-success">
                        androidwatch (prestashop)
                      </span>{" "}
                      - магазин умных часов в СПб. Ссылка на{" "}
                      <a
                        href={"https://bit.ly/3Nrqzeg"}
                        rel={"nofollow noreferrer"}
                        target={"_blank"}
                      >
                        {" "}
                        вебархив
                      </a>
                      ;
                    </li>
                    <li className="description">
                      <span className="text-success"> kinopanda (DLE)</span> -
                      сайт онлайн кинотеатра без рекламы. Ссылка на{" "}
                      <a
                        href={"https://bit.ly/3uxvFNG"}
                        rel={"nofollow noreferrer"}
                        target={"_blank"}
                      >
                        {" "}
                        вебархив
                      </a>
                      ;
                    </li>
                    <li className="description">
                      <span className="text-success">
                        nikefuel (prestashop)
                      </span>{" "}
                      - сайт и продвижение для магазина фитнес браслетов в СПб.
                      Ссылка на{" "}
                      <a
                        href={"https://bit.ly/3DdvWci"}
                        rel={"nofollow noreferrer"}
                        target={"_blank"}
                      >
                        {" "}
                        вебархив
                      </a>
                      ;
                    </li>
                    <li className="description">
                      <span className="text-success">C# и ZennoPoster</span> -
                      для себя и коллег по цеху написал множество утилит для
                      автоматизации SEO задач.
                      <a
                        href={"https://bit.ly/3JLEtWA"}
                        rel={"nofollow noreferrer"}
                        target={"_blank"}
                      >
                        {" "}
                        Пример
                      </a>
                      . Продал 85 копий одной из них -{" "}
                      <a
                        href={"https://bit.ly/3JSju4n"}
                        rel={"nofollow noreferrer"}
                        target={"_blank"}
                      >
                        читать отзывы
                      </a>
                      .
                    </li>
                    <li className="description">
                      <span className="text-success">
                        toriHunter (C# / ZP / xCrud)
                      </span>{" "}
                      - заказ на бота для челночного бизнеса. Парсер 2 досок
                      объявлений (tori.fi и avito) с уведомлениями в телеграм.{" "}
                      <a
                        href={"https://bit.ly/3wFO3qk"}
                        rel={"nofollow noreferrer"}
                        target={"_blank"}
                      >
                        {" "}
                        Подробнее
                      </a>
                      .
                    </li>
                    <li className="description">
                      <span className="text-success">
                        Newshine (wordpress + oxygen)
                      </span>
                      - сайт услуг для бьюти студии в СПб -{" "}
                      <a
                        href={"https://bit.ly/3IFYzjv"}
                        rel={"nofollow noreferrer"}
                        target={"_blank"}
                      >
                        ссылка;{" "}
                      </a>
                    </li>
                    <li className="description">
                      <span className="text-success">oniks (opencart)</span> -
                      магазин канцтоваров, создание и продвижение крупного
                      магазина товаров для офиса. Ссылка на{" "}
                      <a
                        href={"https://bit.ly/3utKIrz"}
                        rel={"nofollow noreferrer"}
                        target={"_blank"}
                      >
                        кейс
                      </a>
                      .{" "}
                    </li>
                    <li className="description">
                      <span className="text-success">CRM (Glide)</span> - заказ
                      на разработку мобильной CRM системы. Приложение нужно было
                      сделать на основе предоставленного excel файла с
                      клиентской базой небольшой студии красоты.{" "}
                      <a
                        href={"https://bit.ly/3uy3o9t"}
                        rel={"nofollow noreferrer"}
                        target={"_blank"}
                      >
                        Результат работы
                      </a>
                      .
                    </li>
                    <li className="description">
                      <span className="text-success">
                        Мастерская СТМ (wordpress + bricks)
                      </span>{" "}
                      - заказ на разработку сайта для автосервиса под ключ с
                      миграцией данных со старого сайта.{" "}
                      <a
                        href={"http://xn----7sbatzjf2ahcdfh3p.xn--p1ai/"}
                        rel={"nofollow noreferrer"}
                        target={"_blank"}
                      >
                        Результат работы
                      </a>
                      .{" "}
                    </li>
                  </ul>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3 className="mb-1">Прочие проекты (React)</h3>
                  <p className={"description text-justify"}>
                    <span className="text-success">Fast-company</span> -
                    небольшое приложение для поиска компании. Реализована
                    регистрация, авторизация, профили пользователей, листинг,
                    стена с комментариями, добавление комментариев. Проект
                    доступен по ссылке:{" "}
                    <a
                      href="https://fast-company.aleksandrl.ru/"
                      target={"_blank"}
                      rel={"noreferrer nofollow"}
                    >
                      fast-company.aleksandrl.ru
                    </a>{" "}
                    Короткое видео демонстрации функционала можно{" "}
                    <a
                      href="https://www.berrycast.com/conversations/7cd95124-b008-55e3-9598-a82e4bf03642"
                      target={"_blank"}
                      rel={"nofollow noreferrer"}
                    >
                      посмотреть по ссылке.
                    </a>{" "}
                    Ссылка на{" "}
                    <a
                      href={
                        "https://github.com/aleksandr-loskutov/jfd-fast-company/tree/deploy"
                      }
                      target={"_blank"}
                      rel={"noreferrer nofollow"}
                    >
                      репозиторий проекта.
                    </a>
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-between mt-1 align-items-center">
                <Col md="4">
                  Стек технологий:
                  <ul>
                    <li className="description">JS(ES6+);</li>
                    <li className="description">React;</li>
                    <li className="description">Redux;</li>
                    <li className="description">Firebase;</li>
                    <li className="description">Bootstrap;</li>
                  </ul>
                </Col>
                <Col md="7">
                  <Card className="mt-0">
                    <div className="card-image h-100">
                      <img
                        alt="..."
                        className="img rounded"
                        style={{ opacity: 0.8 }}
                        src={
                          "https://files.aleksandrl.ru/sites/portfolio/img/fast-company-comment.png"
                        }
                      />
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col>
                  <p className="description mb-2">
                    <span className="text-success">aleksandrl.ru</span> -
                    простой сайт визитка на React. Ничего любопытного. Однако
                    этот сайт, как и все остальные сайты из портфолио развернуты
                    на этом сервере в докер контейнерах на своих портах. Для
                    всех сайтов настроен nginx с обратными прокси, SSL
                    сертификаты, политики CORS и CSP. Для всех проектов настроен
                    workflow на github-actions со своими раннерами для
                    автодеплоя при пуше с поднятием и очисткой контейнеров на
                    сервере - т.е внедрён полноценный CI/CD.
                  </p>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Container>
      </div>
    </div>
  );
}

export default Projects;

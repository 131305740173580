import React from "react";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";

function Certificates(props) {
  return (
    <div className="section blogs-1" id="blogs-1" style={{ paddingTop: 15 }}>
      <Container>
        <h2 className="title text-center" style={{ marginBottom: 5 }}>
          Сертификаты
        </h2>
        <br />
        <Row className="align-items-center justify-content-center">
          <Col lg="3">
            <a
              href="https://disk.yandex.ru/i/ajYJbd2hwxvFYg"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <Card className="card-blog card-background" data-animation={true}>
                <div
                  className="full-background"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("assets/img/certs/jfd.jpg").default +
                      ")",
                  }}
                />
                <CardBody>
                  <div className="content-bottom">
                    <h6 className="card-category">Владилен Минин</h6>
                    <CardTitle tag="h3">Frontend</CardTitle>
                    <button
                      type="button"
                      className="btn-round btn-sm btn btn-primary"
                    >
                      Сертификат
                    </button>
                  </div>
                </CardBody>
              </Card>
            </a>
          </Col>
          <Col lg="3">
            <a
              href="https://disk.yandex.ru/i/VEXIzxDOvK1Afw"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <Card className="card-blog card-background" data-animation={true}>
                <div
                  className="full-background"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("assets/img/certs/html.jpg").default +
                      ")",
                  }}
                />

                <CardBody>
                  <div className="content-bottom">
                    <h6 className="card-category">HTML academy</h6>
                    <CardTitle tag="h3">HTML & CSS</CardTitle>
                    <button
                      type="button"
                      className="btn-round btn-sm btn btn-primary"
                    >
                      Сертификат
                    </button>
                  </div>
                </CardBody>
              </Card>
            </a>
          </Col>
          <Col lg="3">
            <a
              href="https://disk.yandex.ru/i/A5U0oGGb0rM46g"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <Card className="card-blog card-background" data-animation={true}>
                <div
                  className="full-background"
                  style={{
                    backgroundImage:
                      "url('https://files.aleksandrl.ru/sites/portfolio/doc/middle.png')",
                  }}
                />
                <CardBody>
                  <div className="content-bottom">
                    <h6 className="card-category">Яндекс практикум</h6>

                    <CardTitle tag="h3">Middle frontend</CardTitle>
                    <button
                      type="button"
                      className="btn-round btn-sm btn btn-primary"
                    >
                      Диплом
                    </button>
                  </div>
                </CardBody>
              </Card>
            </a>
          </Col>
          <Col lg="3">
            <a
              href="https://disk.yandex.ru/i/EO7rUwwCo1bRdA"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <Card className="card-blog card-background" data-animation={true}>
                <div
                  className="full-background"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("assets/img/certs/english.jpg").default +
                      ")",
                  }}
                />
                <CardBody>
                  <div className="content-bottom">
                    <h6 className="card-category">EFSET.ORG</h6>

                    <CardTitle tag="h3">English: B2</CardTitle>
                    <button
                      type="button"
                      className="btn-round btn-sm btn btn-primary"
                    >
                      Сертификат
                    </button>
                  </div>
                </CardBody>
              </Card>
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Certificates;

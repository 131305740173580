/*!

=========================================================
* BLK Design System PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
// import {
//
// } from "reactstrap";

// core components

// Sections for this page
// (we've divided this page into multiple react components to make it a bit more readable)
import MainTitle from "../components/mainTitle.js";
import Accordion from "../components/accordion";
import Projects from "../components/projects";
import Certificates from "../components/certificates";
import FooterComponent from "../components/footerComponent";
import NavbarComponent from "../components/navbarComponent";
import Stack from "../components/stack";

export default function Presentation() {
  const wrapper = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    wrapper.current.scrollTop = 0;
    document.body.classList.add("presentation-page");
    return function cleanup() {
      document.body.classList.remove("presentation-page");
    };
  }, []);
  return (
    <>
      <NavbarComponent />
      <div className="wrapper index-page" ref={wrapper}>
        <div className="main">
          <MainTitle />
          <Projects />
          <Certificates />
          <Stack />
          <Accordion />
          <FooterComponent />
        </div>
      </div>
    </>
  );
}
